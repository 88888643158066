<template>
    <div id="OurJourneys" class="journey-area pt-20">

      <!-- <h2 class="mt-50"> {{ $t('Contents.OurJourney') }}</h2> -->
    
      <div class="col-12">
          <!-- Section Title Start -->
          <div class="section-title" data-aos="fade-up" data-aos-delay="300">
               <h2 class="mt-10"> {{ $t('Contents.OurJourney') }}</h2>
               <div class="c-line"></div>
          </div>
          <!-- Section Title End -->
      </div>

         <!--   <img src="assets/images/jour.png"> -->

           <div class="container">
             <div class="row">
               <div class="col-md-3">
                 <div class="grid-item begin-jour">
                   <div>

                    <h3 class="jour-header"> {{begin.year}} </h3>
                    <p> {{begin.about}} </p>
                   </div>
                 </div>
               </div>
               <div class="col-md-9">
                 <div class="grid-container" >
                  <div class="grid-item" v-for="(list, index) in lists" :key="index" >
                    <h3 class="jour-header"> {{list.year}} </h3>
                    <p>{{list.about}}</p>
                  </div>  
                </div>
               </div>

             </div>
            
             
           </div>
    </div>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

// import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
// import 'swiper/css';
export default {
   data() {
     return {
       swiperOption: {
         loop: true,
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 40,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows : true
          },
          pagination: {
            el: '.swiper-pagination'
          }
        },        
     }
   },
   computed:{
          begin(){
            return {
              year: 2015,
              about: this.$t('Contents.Journey.About2015'),
            }
          },
          lists(){
            return [
                
                {
                    year : 2016 ,
                    about: this.$t('Contents.Journey.About2016'),
                    image: "assets/images/expansion2.png"
                },
                {
                    year : 2017 ,
                    about: this.$t('Contents.Journey.About2017'),
                    image: "assets/images/light1.png"
                },
                {
                    year : 2018 ,
                    about: this.$t('Contents.Journey.About2018'),
                    image: "assets/images/marcked1.png"
                },
                {
                    year : 2019 ,
                    about: this.$t('Contents.Journey.About2019'),
                    image: "assets/images/growing1.png"
                },
                {
                    year : 2020 ,
                    about: this.$t('Contents.Journey.About2020'),
                    image: "assets/images/protfolio1.png"
                },
                {
                    year : 2021 ,
                    about: this.$t('Contents.Journey.About2021'),
                    image: "assets/images/local1.png"
                }
        ]
          },
          direct(){            
            return this.$cookies.get('JmDirection')
          }
        }
}
</script>


<style lang="scss" scoped>
  .example-3d {
    width: 100%;
    height: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  

   .journey-area {
        position: relative;
        background-color: none;
        padding-top: 60px !important;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .journey-area::before {
        height: 100vh;
        content: '';
        position: fixed;
        z-index: -1;
        display: block;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-image: url("/assets/images/bg-min.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    


  .c-content-box.c-size-md {
    padding: 60px 0;
  }

  .box{
    background-color: rgba(255, 255, 255, 0.7);
    padding: 40px;
    text-align: right;
    margin: 10px;
  }

  .card-jour{
    padding: 40px;
  }

  .grid-container {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(0, 0, 0, 0.8);
  padding: 10px;
  font-size: 18px;
  text-align: inherit;  
}

.grid-item p {
  font-size: 18px;
}
.jour-header{
    margin: 0 0 20px 0;
    color: #3f444a;
    text-align:center;
}

.begin-jour{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.7);

  background-color: rgba(0, 0, 0, 0.8) ;
  border: 2px solid rgba(255, 255, 255, 0.7);
  padding: 20px;
  font-size: 18px;
  text-align: center;
  color: white; 
}

.begin-jour p {
  font-size: 20px;
  color:white;
}

.begin-jour h3{
  color: white;
}

.grid-item:hover  {
  background-color: #208e87;
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: white;
  
}

.grid-item:hover  p{
  color:white;
}


@media screen and (max-width :768px) { 
  .grid-container {
    padding: 0px !important;
    padding-top: 10px !important;
  }
}

@media screen and (max-width :480px) { 
  .grid-container {
    grid-gap: 10px 0px;
    padding: 0px;
    /* padding: 10px; */
    padding-top: 10px;
  }
}
</style>